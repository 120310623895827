import React, { useState } from "react";

import { Button, Empty, Input } from "antd";

export const EnterName: React.FC<{
  onSubmitName: (name: string) => void;
  disabled: boolean;
}> = ({ onSubmitName, disabled }) => {
  const [name, setName] = useState("");
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="Enter your name here"
      style={{ paddingTop: "20%" }}
    >
      <Input.Group compact>
        <Input
          disabled={disabled}
          style={{ minWidth: "30vh", width: "10%" }}
          placeholder="Viraj Garage"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onPressEnter={() => onSubmitName(name)}
        />
        <Button
          type="primary"
          disabled={disabled}
          onClick={() => onSubmitName(name)}
        >
          Submit
        </Button>
      </Input.Group>
    </Empty>
  );
};
