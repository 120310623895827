import { QuestionCircleFilled } from "@ant-design/icons";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";

export const NotFound: React.FC = () => (
  <Result
    style={{ margin: "auto" }}
    title="Page not found"
    icon={<QuestionCircleFilled />}
    extra={
      <Link to="/">
        <Button type="primary">Back Home</Button>
      </Link>
    }
  />
);
