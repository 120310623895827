import { ArrowLeftOutlined } from "@ant-design/icons";
import { Badge, List, Statistic } from "antd";
import React from "react";
import { CustomAvatar } from "./avatar";

export const Users: React.FC<{
  users: readonly {
    name: string;
    uuid: string;
    score: number;
    online: boolean;
  }[];
  leader?: string;
}> = ({ users, leader }) => (
  <List
    bordered
    dataSource={[...users].sort((a, b) => b.score - a.score)}
    renderItem={(item) => (
      <List.Item key={item.uuid}>
        <>
          <Badge dot color={item.online ? "green" : "red"}>
            <CustomAvatar user={item} />
          </Badge>
          {item.name} {item.uuid === leader ? <ArrowLeftOutlined /> : null}
          <Statistic value={item.score} />
        </>
      </List.Item>
    )}
  />
);
