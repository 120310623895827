import { HomeFilled } from "@ant-design/icons";
import { Result, Tooltip, Button, Typography, QRCode } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSgMutation } from "../client";

export const CreateRoom: React.FC = () => {
  const [roomId, setRoomId] = useState<string>();
  const { mutate: createRoom, isLoading } = useSgMutation({
    path: "/create-room",
    method: "post",
  });
  if (roomId) {
    const roomLink = `/room/${roomId}`;
    return (
      <Result
        style={{ margin: "auto" }}
        status="success"
        title="Room created successfully."
        subTitle="Share this link to play with others"
        extra={
          <>
            <QRCode
              style={{ margin: "auto" }}
              value={window.location.origin + roomLink}
            />
            <Typography.Paragraph style={{ margin: 15 }} copyable code>{`${
              window.location.origin + roomLink
            }`}</Typography.Paragraph>
            <Link to={roomLink}>
              <Button type="primary">Go to room</Button>
            </Link>
          </>
        }
      />
    );
  }
  return (
    <Result
      style={{ margin: "auto" }}
      icon={<HomeFilled />}
      status="info"
      title="Create a room"
      subTitle="We're running into some Spotify rate limiting issues; we're working on a resolution! You may get an error when creating a room."
      extra={[
        <Tooltip key="random" title="Let the computer choose songs for you">
          <Button
            disabled={isLoading}
            type="primary"
            key="console"
            onClick={() => {
              createRoom(
                { type: "RANDOM" },
                {
                  onSuccess: (res) => {
                    setRoomId(res.data.roomId);
                  },
                }
              );
            }}
          >
            random songs
          </Button>
        </Tooltip>,
        <Tooltip key="custom" title="You always pick the song">
          <Button
            disabled={isLoading}
            onClick={() => {
              createRoom(
                { type: "CUSTOM" },
                {
                  onSuccess: (res) => {
                    setRoomId(res.data.roomId);
                  },
                }
              );
            }}
          >
            custom game
          </Button>
        </Tooltip>,
      ]}
    />
  );
};
