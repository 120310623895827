import { useNavigate, useParams } from "react-router-dom";
import { useSgQuery } from "./client";

export const useRoomId = (): string => {
  const { roomId } = useParams();
  const navigate = useNavigate();
  if (!roomId) {
    navigate("/room-not-found");
    return "";
  }
  return roomId;
};

export const useUser = () => {
  const roomId = useRoomId();
  const userId = localStorage.getItem(roomId);
  const {
    data: userData,
    isLoading: userLoading,
    isError,
  } = useSgQuery({
    path: "/user",
    method: "get",
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    params: { roomId, userId: userId! },
    useQueryOptions: { enabled: Boolean(userId) },
  });
  const user = userData?.data;
  return { user, isLoading: userLoading, isError };
};
