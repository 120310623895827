import { Fetcher, OpArgType } from "openapi-typescript-fetch";
import {
  useQuery,
  useMutation,
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import type { paths } from "./generated/schema";
import { config } from "./config";

const fetcher = Fetcher.for<paths>();

fetcher.configure({
  baseUrl: config.serverUrl,
  init: {
    headers: {
      "Content-Type": "application/json",
    },
  },
});

export const sgFetch = <
  P extends keyof paths,
  Method extends keyof paths[P]
>(args: {
  path: P;
  method: Method;
  params: OpArgType<paths[P][Method]>;
  options?: RequestInit;
}) => {
  const query = fetcher.path(args.path).method(args.method).create();
  return query(args.params, args.options);
};

export const useSgQuery = <
  P extends keyof paths,
  Method extends keyof paths[P] & ("get" | "head" | "options")
>(args: {
  path: P;
  method: Method;
  params: OpArgType<paths[P][Method]>;
  queryKey?: Record<string, string>;
  fetchOptions?: RequestInit;
  useQueryOptions?: Pick<UseQueryOptions, "enabled" | "retry">; // add more options when needed
}) => {
  const defaultQueryKey = {
    path: args.path,
    method: args.method,
    params: args.params,
    fetchOptions: args.fetchOptions,
  };
  const queryKey = args.queryKey ? [args.queryKey] : [defaultQueryKey];
  return useQuery({
    ...args.useQueryOptions,
    queryKey,
    queryFn: async () => sgFetch({ ...args }),
  });
};

export const useSgMutation = <
  P extends keyof paths,
  Method extends keyof paths[P] & "post"
>(args: {
  path: P;
  method: Method;
  fetchOptions?: RequestInit;
  useMutationOptions?: Pick<UseMutationOptions, "networkMode">;
}) =>
  useMutation({
    ...args.useMutationOptions,
    mutationFn: async (variables: OpArgType<paths[P][Method]>) =>
      sgFetch({ ...args, params: variables }),
  });
