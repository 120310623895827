import { HourglassOutlined, SoundOutlined } from "@ant-design/icons";
import { Result, AutoComplete, Button, Statistic, Typography } from "antd";
import { useState } from "react";
import { sgFetch, useSgMutation } from "../client";
import { useRoomId, useUser } from "../hooks";
import { Song, User } from "../../../shared/socket-types";

export const CustomChooser: React.FC<{
  setSongState: React.Dispatch<
    React.SetStateAction<"BEFORE" | "DURING" | "AFTER">
  >;
  currentLeader?: User;
  song: Song;
}> = ({ setSongState, currentLeader, song }) => {
  const roomId = useRoomId();

  const { mutate: pickSong, isLoading } = useSgMutation({
    path: "/pick-song",
    method: "post",
  });

  const { user } = useUser();
  const [options, setOptions] = useState<
    { value: string; label: string; url: string }[]
  >([]);
  const [songLockedIn, setSongLockedIn] = useState(false);

  const [value, setValue] = useState("");
  const [selectedSong, setSelectedSong] = useState<{
    id: string;
    url: string;
  }>();

  if (user?.uuid !== currentLeader?.uuid) {
    return (
      <Result
        style={{ margin: "auto" }}
        icon={<HourglassOutlined />}
        title={`Hang tight while ${
          currentLeader?.name ?? "someone else"
        } picks the next song`}
      />
    );
  }
  return (
    <Result
      icon={<SoundOutlined />}
      title="Choose a song!"
      extra={
        <>
          <AutoComplete
            options={options}
            style={{ width: "100%", maxWidth: 400 }}
            onSelect={(id, metadata) => {
              setSelectedSong({ id, url: metadata.url });
              setValue(metadata.label);
            }}
            notFoundContent="Not Found"
            value={value}
            onChange={setValue}
            onSearch={async (query) => {
              // todo debounce
              if (!query) {
                setOptions([]);
                return;
              }
              const tracks = (
                await sgFetch({
                  path: "/search",
                  params: { query },
                  method: "get",
                })
              ).data;
              const results = tracks.map((i) => ({
                value: i.id,
                label: `${i.name} by ${i.artists.join(", ")}`,
                url: i.url,
              }));
              setOptions(results);
            }}
            placeholder="search for a song"
          />
          <br />
          <br />
          <Button
            disabled={!selectedSong || isLoading}
            type="primary"
            onClick={() => {
              if (!user || !selectedSong) {
                return;
              }
              pickSong(
                {
                  roomId,
                  userId: user.uuid,
                  trackId: selectedSong.id,
                  backupUrl: selectedSong.url,
                },
                {
                  onSuccess: () => {
                    setSongState("BEFORE");
                    setSongLockedIn(true);
                  },
                }
              );
            }}
          >
            Confirm
          </Button>
          <br />
          <br />
          <Statistic.Countdown value={song.startsAtMs} />
          <Typography.Text>
            until
            {songLockedIn ? " your song starts" : " a random song is chosen"}
          </Typography.Text>
        </>
      }
    />
  );
};
