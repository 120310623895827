import { useNavigate, useParams } from "react-router-dom";
import { useSgMutation, useSgQuery } from "../client";
import { EnterName } from "./enter-name";
import { Game } from "./game";
import { Loading } from "./loading";

const RoomInternal: React.FC<{ roomId: string }> = ({ roomId }) => {
  const { mutateAsync: newUser, isLoading: newUserLoading } = useSgMutation({
    path: "/user",
    method: "post",
  });
  const userId = localStorage.getItem(roomId);
  const navigate = useNavigate();
  if (!roomId) {
    navigate("/room-not-found");
    return null;
  }

  if (!userId) {
    return (
      <EnterName
        disabled={newUserLoading}
        onSubmitName={async (name) => {
          const res = await newUser({ roomId, name });
          localStorage.setItem(roomId, res.data.uuid);
        }}
      />
    );
  }

  return <Game />;
};

export const Room: React.FC = () => {
  const { roomId } = useParams();
  const { isLoading, isError } = useSgQuery({
    path: "/room",
    method: "get",
    params: { roomId: roomId ?? "" },
    useQueryOptions: { enabled: Boolean(roomId), retry: false },
  });
  const navigate = useNavigate();

  if (!roomId) {
    navigate("/");
    return <Loading />;
  }

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    navigate("/");
    return <Loading />;
  }
  return <RoomInternal roomId={roomId} />;
};
