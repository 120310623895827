import { Avatar } from "antd";

export const CustomAvatar: React.FC<{
  user: { uuid: string; name: string };
}> = ({ user }) => {
  const letter = user.name[0].toUpperCase();
  return (
    <Avatar style={{ backgroundColor: `#${user.uuid.slice(-6)}` }}>
      {letter}
    </Avatar>
  );
};
