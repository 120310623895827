import { Button, Layout, Space, Typography, Grid } from "antd";

import { Socket, io } from "socket.io-client";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  ServerToClientEvents,
  ClientToServerEvents,
} from "../../../shared/socket-types";
import { useSocket } from "../socket-events/hooks";
import { Messages } from "./messages";
import { Users } from "./users";
import { Player } from "./music-player";
import { config } from "../config";
import { CustomAvatar } from "./avatar";
import { ConnectionStatus } from "./connection-status";
import { useRoomId, useUser } from "../hooks";
import { CustomChooser } from "./custom-chooser";
import { Loading } from "./loading";

const { Header, Content, Footer, Sider } = Layout;
const { useBreakpoint } = Grid;

export const Game: React.FC = () => {
  const roomId = useRoomId();
  const { user, isLoading } = useUser();
  const [collapsed, setCollapsed] = useState(false);
  // audio ref needs to be in this parent component so the browser can notice
  // interaction with the dom so it can play
  const audioRef = useRef(new Audio());
  const socket: Socket<ServerToClientEvents, ClientToServerEvents> = useMemo(
    () =>
      io(`${config.serverUrl}`, {
        auth: (cb) => {
          cb({
            uuid: user?.uuid,
            roomId,
          });
        },
      }),
    [user, roomId]
  );
  const { isConnected, messages, users, song, gameType, currentLeader } =
    useSocket(socket);
  const usersById = new Map(users.map((u) => [u.uuid, u]));
  const [songState, setSongState] = useState<"BEFORE" | "DURING" | "AFTER">(
    "AFTER"
  );
  const breakpoint = useBreakpoint();
  const connectionStatusSmall = breakpoint.xs ?? breakpoint.sm ?? false;

  useEffect(() => {
    if (!song) {
      return undefined;
    }
    const now = new Date().getTime();
    const msUntilSongStarts = song.startsAtMs - now;
    const msUntilSongEnds = song.endsAtMs - now;
    if (msUntilSongStarts > 0) {
      setSongState("BEFORE");
    } else if (msUntilSongEnds > 0) {
      setSongState("DURING");
    } else {
      setSongState("AFTER");
    }

    if (msUntilSongEnds > 0) {
      const timerUntilSongStarts = setTimeout(() => {
        setSongState("DURING");
      }, msUntilSongStarts);
      const timerUntilSongEnds = setTimeout(() => {
        setSongState("AFTER");
      }, msUntilSongEnds);
      return () => {
        clearTimeout(timerUntilSongStarts);
        clearTimeout(timerUntilSongEnds);
      };
    }
    return undefined;
  }, [song, setSongState]);

  if (isLoading || !user || !gameType || !song) {
    return <Loading />;
  }

  socket.auth = { uuid: user.uuid };

  return (
    <>
      <Sider
        breakpoint="md"
        width={300}
        collapsedWidth={0}
        collapsible
        onCollapse={setCollapsed}
      >
        <div style={{ margin: "2vh", display: collapsed ? "none" : "block" }}>
          <Typography.Title level={3}>Players</Typography.Title>
          <Users users={users} leader={currentLeader} />
          <Button
            style={{
              margin: "2vh",
              bottom: 0,
              position: "fixed",
            }}
            onClick={() => {
              window.location.replace("/logout");
            }}
          >
            Sign out
          </Button>
        </div>
      </Sider>
      <Layout style={{ width: "100%" }}>
        <Header>
          <div>
            <Space direction="horizontal">
              <CustomAvatar user={user} />
              <Typography.Title
                level={2}
                style={{
                  margin: "auto",
                  maxWidth: "20vh",
                }}
                ellipsis
              >
                {user.name}
              </Typography.Title>
              <Typography.Title
                hidden={collapsed}
                level={2}
                style={{
                  margin: "auto",
                  marginLeft: "10vh",
                  minWidth: "20vh",
                }}
              >
                Song Game!
              </Typography.Title>
            </Space>
            <Space
              style={{ float: "right" }}
              hidden={connectionStatusSmall && !collapsed}
            >
              <ConnectionStatus
                connected={isConnected}
                small={connectionStatusSmall}
              />
            </Space>
          </div>
        </Header>
        <Content style={{ padding: "50px 50px", height: "90vh" }}>
          <div style={{ height: "400px" }}>
            {songState === "BEFORE" &&
            gameType === "CUSTOM" &&
            !song.pickedBy ? (
              <CustomChooser
                setSongState={setSongState}
                currentLeader={
                  currentLeader ? usersById.get(currentLeader) : undefined
                }
                song={song}
              />
            ) : (
              <Player song={song} songState={songState} audioRef={audioRef} />
            )}
          </div>
          <div>
            <Messages
              messages={messages}
              users={usersById}
              onSendMessage={(msg) => {
                socket.emit("message", roomId, msg);
              }}
            />
          </div>
        </Content>
        <Footer />
      </Layout>
    </>
  );
};
