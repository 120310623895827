import "antd/dist/reset.css";
import React from "react";

import { App, ConfigProvider, Layout, theme, Typography } from "antd";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Routes, Route, BrowserRouter, Outlet } from "react-router-dom";
import { CreateRoom } from "./components/create-room";
import { config } from "./config";
import { Logout } from "./components/logout";
import { Room } from "./components/room";
import { NotFound } from "./components/not-found";
import ReactGA from "react-ga";
const TRACKING_ID = "G-945E2E5E33";

if (config.environment === "prod") {
  ReactGA.initialize(TRACKING_ID);
}

const GlobalLayout: React.FC = () => (
  <Layout style={{ width: "100%", height: "100%", position: "fixed" }}>
    <Outlet />
  </Layout>
);

function SongGame() {
  const { notification } = App.useApp();

  const queryClient = new QueryClient({
    mutationCache: new MutationCache({
      onError: (error) => {
        notification.error({
          message:
            (error as { data: { message: string } })?.data?.message ??
            "Error in request",
        });
      },
    }),
    queryCache: new QueryCache({
      onError: (error) => {
        notification.error({
          message:
            (error as { data: { message: string } })?.data?.message ??
            "Error in request",
        });
      },
    }),
  });
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<GlobalLayout />}>
          <Route index element={<CreateRoom />} />
          <Route path="logout" element={<Logout />} />
          <Route path="room/:roomId" element={<Room />} />
          <Route
            path="spotify-error"
            element={<Typography.Paragraph>Spotify Error</Typography.Paragraph>}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      {config.environment === "local" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  );
}

function AppWithProviders() {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {},
      }}
    >
      <App>
        <BrowserRouter>
          <SongGame />
        </BrowserRouter>
      </App>
    </ConfigProvider>
  );
}

export default AppWithProviders;
