import { useEffect, useState } from "react";
import { Socket } from "socket.io-client";
import {
  ServerToClientEvents,
  ClientToServerEvents,
  Message,
  Song,
  User,
  GameType,
} from "../../../shared/socket-types";
import { useRoomId, useUser } from "../hooks";

export const useLocalStorage = <T>(key: string) => {
  const [value, setValue] = useState<T | null>(() => {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }
    return JSON.parse(item);
  });

  const storeValue = (val: T) => {
    localStorage.setItem(key, JSON.stringify(val));
    setValue(val);
  };

  return [value, storeValue] as const;
};

export const useSocket = (
  socket: Socket<ServerToClientEvents, ClientToServerEvents>
) => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [messages, setMessages] = useState<readonly Message[]>([]);
  const [users, setUsers] = useState<readonly User[]>([]);
  const [song, setSong] = useState<Song>();
  const [gameType, setGameType] = useState<GameType>();
  const [currentLeader, setCurrentLeader] = useState<string>();
  const { user } = useUser();
  const roomId = useRoomId();

  useEffect(() => {
    if (!user) {
      // eslint-disable-next-line no-console
      console.warn("socket not ready");
      return undefined;
    }
    socket.on("connect", () => {
      setIsConnected(true);
      socket.emit("sync", roomId);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    socket.on("sync", (state) => {
      setMessages(state.messages);
      setUsers(state.users);
      setUsers(state.users);
      setSong(state.currentSong);
      setGameType(state.gameType);
      setCurrentLeader(state.currentLeader);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("sync");
    };
  }, [socket, user, roomId]);

  return { isConnected, messages, users, song, gameType, currentLeader };
};
