import { Input, Timeline, Typography } from "antd";
import { useState } from "react";
import { Message } from "../../../shared/socket-types";

const MessageItem: React.FC<{
  message: Message;
  users: Map<string, { name: string }>;
}> = ({ message, users }) => {
  const intentToColor = {
    CORRECT: "green",
    INFO: "grey",
    UPDATE: "blue",
  } as const;

  return (
    <Timeline.Item color={intentToColor[message.intent]}>
      <Typography.Text strong>
        {message.userId ? users.get(message.userId)?.name ?? "??" : ""}
      </Typography.Text>{" "}
      {message.content}
    </Timeline.Item>
  );
};

export const Messages: React.FC<{
  messages: readonly Message[];
  users: Map<string, { name: string }>;
  onSendMessage: (message: string) => void;
}> = ({ messages, users, onSendMessage }) => {
  const [message, setMessage] = useState("");
  const messageTooLong = message.length > 450;
  return (
    <>
      <Input
        placeholder="Guess here"
        size="large"
        value={message}
        status={messageTooLong ? "error" : undefined}
        onChange={(x) => setMessage(x.target.value)}
        onPressEnter={() => {
          if (message && !messageTooLong) {
            onSendMessage(message);
            setMessage("");
          }
          // could cache locally before getting response if slow, but might end up with misordered messages
        }}
      />
      <Timeline
        style={{
          maxHeight: "50vh",
          overflow: "auto",
          paddingTop: "1vh",
        }}
      >
        {messages.map((m, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <MessageItem key={i} message={m} users={users} />
        ))}
      </Timeline>
    </>
  );
};
