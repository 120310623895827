import { LoadingOutlined } from "@ant-design/icons";
import { Result } from "antd";

export const Loading: React.FC = () => (
  <Result
    icon={<LoadingOutlined />}
    title="Loading..."
    style={{ margin: "auto" }}
  />
);
