type Config = {
  serverUrl: string;
  environment: "local" | "prod";
};

const configLocal = {
  serverUrl: "http://127.0.0.1:3001",
  // serverUrl: "http://<local network IP>:3001", // Local network IP to view on another device
  // serverUrl: "https://api.songgame.app", // test frontend against prod
  environment: "local",
} satisfies Config;

const configProd = {
  serverUrl: "https://api.songgame.app",
  environment: "prod",
} satisfies Config;

const config = process.env.NODE_ENV === "production" ? configProd : configLocal;

export { config };
