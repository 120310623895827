import { CaretRightOutlined, HourglassOutlined } from "@ant-design/icons";
import {
  Button,
  Result,
  Skeleton,
  Space,
  Statistic,
  Tooltip,
  Typography,
} from "antd";

import { useEffect, useState } from "react";
import { Song } from "../../../shared/socket-types";

export const Player: React.FC<{
  song: Song;
  songState: "BEFORE" | "DURING" | "AFTER";
  audioRef: React.MutableRefObject<HTMLAudioElement>;
}> = ({ song, songState, audioRef }) => {
  const [showPlayButton, setShowPlayButton] = useState(false);
  useEffect(() => {
    if (audioRef.current.src !== song.preview_url) {
      // eslint-disable-next-line no-param-reassign
      audioRef.current.src = song.preview_url;
    }
    const playIfCan = async () => {
      if (songState === "DURING") {
        try {
          await audioRef.current?.play();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          // user hasn't interacted with the page
          setShowPlayButton(true);
        }
      } else {
        audioRef.current.pause();
        setShowPlayButton(false);
      }
    };
    playIfCan();
  }, [song, songState, showPlayButton, audioRef]);

  if (songState === "BEFORE") {
    return (
      <Result
        icon={<HourglassOutlined />}
        title="Next song coming up in"
        subTitle={
          <Statistic.Countdown
            value={song.startsAtMs}
            format="s"
            suffix="seconds"
          />
        }
      />
    );
  }

  function skeletonWithTooltip() {
    return (
      <div>
        <Tooltip title="guess and chat in the box below">
          <span>
            <Skeleton.Input />
          </span>
        </Tooltip>
      </div>
    );
  }

  return (
    <>
      <div>
        <Space style={{ width: "100%" }}>
          <Typography.Title style={{ width: "120px" }} level={3} ellipsis>
            Song Title:
          </Typography.Title>
          {song.guessedTitle ? (
            <Typography.Title
              type="success"
              style={{ maxWidth: "20vh" }}
              level={3}
            >
              {song.guessedTitle}
            </Typography.Title>
          ) : (
            skeletonWithTooltip()
          )}
        </Space>

        <Space>
          <Typography.Title style={{ width: "120px" }} level={3}>
            Artist:
          </Typography.Title>
          {song.guessedArtists ? (
            <Typography.Title
              ellipsis
              type="success"
              style={{ maxWidth: "20vh" }}
              level={3}
            >
              {song.guessedArtists.join(", ")}
            </Typography.Title>
          ) : (
            skeletonWithTooltip()
          )}
        </Space>
      </div>
      <div>
        {" "}
        {showPlayButton ? (
          <Result
            icon={<CaretRightOutlined />}
            extra={
              <Button type="primary" onClick={() => setShowPlayButton(false)}>
                Play
              </Button>
            }
          />
        ) : (
          <Statistic.Countdown
            format="s"
            valueStyle={{ textAlign: "center", fontSize: "80px" }}
            value={song.endsAtMs}
          />
        )}
      </div>
    </>
  );
};
