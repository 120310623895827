import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";

export const ConnectionStatus: React.FC<{
  connected: boolean;
  small: boolean;
}> = ({ connected, small }) => {
  if (connected) {
    return (
      <>
        <CheckCircleOutlined />{" "}
        {small ? null : <Typography.Text>connected</Typography.Text>}
      </>
    );
  }
  return (
    <>
      <CloseCircleOutlined style={{ color: "red" }} />
      {small ? null : <Typography.Text>disconnected</Typography.Text>}
    </>
  );
};
